import React from 'react'
import { Container, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import linkedin from '../assets/img/nav-icon1.svg';
import itchio from "../assets/img/itch-io-brands-solid.svg";
import youtube from "../assets/img/youtube-brands-solid.svg";
import logo from "../assets/img/Logo_Alan.png"

export const Footer = () => {
  const email = "alannares1@gmail.com";

  const handleCopy = () =>{
      navigator.clipboard.writeText(email);
      alert("Correo copiado: " + email);
  };

  const link_linkedin = "https://www.linkedin.com/in/alan-ernesto-nares-molina-b68b9716b/";
  const link_youtube = "https://www.youtube.com/@MrUmagon";
  const link_itchIo = "https://mrumagon.itch.io/";

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={4}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={4}>
            <InputGroup className="mb-3" style={{ maxWidth: "400px", padding: "20px 0 0 0" }}>
              <FormControl value={email} readOnly aria-label="Correo electrónico"/>
                <Button variant="primary" onClick={handleCopy} style={{backgroundColor: "#6231AD", borderColor: "#6231AD"}} >
                  Copy
                </Button>
            </InputGroup>
          </Col>
          <Col size={12} sm={4} className="text-center text-sm-end">
            <div className="social-icon">
              <a href={link_linkedin} target="_blank"><img src={linkedin} alt="" /></a>
              <a href={link_youtube} target="_blank"><img src={youtube} alt="" /></a>
              <a href={link_itchIo} target="_blank"><img src={itchio} alt="" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}