import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Inicio } from './Components/Inicio';
import { Proyectos } from './Components/Proyectos';
import { Menu } from './Components/Menu';
import { Footer } from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Menu/>
      <div className="gradient-background">
        <Routes>
          <Route path='/' element={<Inicio/>} />
          <Route path='/proyectos' element={<Proyectos/>} />
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
