import React from 'react'
import { Container, Row, Col, InputGroup, FormControl, Button, Image } from "react-bootstrap";
import IMG1 from "../assets/img/AlanNaresPhoto.png";

export const Description = () => {
    const email = "alannares1@gmail.com";

    const handleCopy = () =>{
        navigator.clipboard.writeText(email);
        alert("Correo copiado: " + email);
    };

    return (
        <div style={{marginTop: "60px", marginBottom: "120px"}}>
            <section className="" id="description">
                <Container>
                    <Row className="aligh-items-center">
                        <Col xs={12} md={6} xl={5}>
                            <Image src={IMG1} rounded className="img-fluid w-75" />
                        </Col>
                        <Col xs={12} md={6} xl={7}>
                            <h1 style={{padding: "20px 0 20px 0"}}>About me!</h1>
                            <p>Hola! Soy Alan Nares, me apasiona el mundo de los videojuegos y la programación.
                                Me especializo en Unity donde he creado aplicaciones completas en realidad virtual,
                                videojuegos para dispositivos moviles y pequeños sistemas para proyectos personales.
                            </p>
                            <InputGroup className="mb-3" style={{ maxWidth: "400px", padding: "20px 0 0 0" }}>
                                <FormControl
                                    value={email}
                                    readOnly
                                    aria-label="Correo electrónico"
                                />
                                <Button variant="primary" onClick={handleCopy} style={{backgroundColor: "#6231AD", borderColor: "#6231AD"}}>
                                    Copy
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
