import React from "react";
import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logoAlan from "../assets/img/Logo_Alan.png";
import linkedin from '../assets/img/nav-icon1.svg';
import itchio from "../assets/img/itch-io-brands-solid.svg";
import youtube from "../assets/img/youtube-brands-solid.svg";

export const Menu = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  const link_linkedin = "https://www.linkedin.com/in/alan-ernesto-nares-molina-b68b9716b/";
  const link_youtube = "https://www.youtube.com/@MrUmagon";
  const link_itchIo = "https://mrumagon.itch.io/";

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logoAlan} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" className={activeLink === '/' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('/')}>Home</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'proyectos' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('proyectos')}>Projects</Nav.Link>
              <Nav.Link href="#description" className={activeLink === 'contacto' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contacto')}>About me</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href={link_linkedin} target="_blank"><img src={linkedin} alt="" /></a>
                <a href={link_youtube} target="_blank"><img src={youtube} alt="" /></a>
                <a href={link_itchIo} target="_blank"><img src={itchio} alt="" /></a>
              </div>
              <Nav.Link href="/contacto">
                <button className="vvd"><span>Contact me!</span></button>
              </Nav.Link>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
