import React from 'react'
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from './ProjectCard';
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import LolMap from "../assets/img/ProjectsImg/3dLolMap.png"
import AquaRetro from "../assets/img/ProjectsImg/AquaRetro.png"
import AquaRetro3D from "../assets/img/ProjectsImg/AquaRetro3D.png"
import Gachapon from "../assets/img/ProjectsImg/Gachapon.png"
import NPCs from "../assets/img/ProjectsImg/NPCs.png"
import Pinatazo from "../assets/img/ProjectsImg/Pinatazo.png"
import RollBall3D from "../assets/img/ProjectsImg/RollBall3D.png"
import TAS2 from "../assets/img/ProjectsImg/TAS2.png"
import VisualNovel from "../assets/img/ProjectsImg/VisualNovel.png"
import Zensay from "../assets/img/ProjectsImg/Zensay.png"

export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: TAS2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: Pinatazo,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: RollBall3D,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: AquaRetro,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: AquaRetro3D,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: Gachapon,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: NPCs,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: LolMap,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: VisualNovel,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: Zensay,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInDown": ""}>
                <h2>Projects</h2> 
                <p style={{paddingBottom: "40px"}}>Check out the games and systems I have worked on.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
